<template>
  <b-overlay :show="isFetchingCmsSite">
    <b-row>
      <b-col class="text-right mb-2">
        <b-button
          variant="success"
          @click="$bvModal.show('cms-create-promotion')"
          >New Promotion</b-button
        >
      </b-col>
    </b-row>
    <b-table :fields="fields" :items="cmsPromotions" responsive>
      <template #cell(#)="data">
        <div>
          {{ data.index + 1 }}
        </div>
      </template>
      <template #cell(imageSrc)="data">
        <b-img :src="data.value" width="auto" height="150px"></b-img>
      </template>
      <template #cell(id)="data">
        <div>
          <b-button
            size="sm"
            variant="danger"
            @click="onRemovePromotion(data.value)"
            >Remove</b-button
          >
        </div>
      </template>
    </b-table>
    <CreateCmsPromotion />
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    CreateCmsPromotion: () => import('./create-promotion.vue'),
  },
  data() {
    return {
      fields: [
        '#',
        {
          key: 'title',
          label: 'Title',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'imageSrc',
          label: 'Image',
        },
        {
          key: 'content',
          label: 'Content',
        },
        {
          key: 'position',
          label: 'Position',
        },
        {
          key: 'id',
          label: 'Actions',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['cmsPromotions', 'siteId']),
    ...mapState({
      isFetchingCmsSite: (state) => state.cms.isFetchingCmsSite,
    }),
  },
  methods: {
    ...mapActions(['removeCmsPromotion']),
    onRemovePromotion(promotionId) {
      this.removeCmsPromotion({ siteId: this.siteId, promotionId })
    },
  },
}
</script>
